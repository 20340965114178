<template>
  <div v-if="breadcrumbList?.length != 0" class="breadcrumb">
    <div class="breadcrumb-inner">
      <ul>
        <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx" @click="routeTo(idx)">
          <span v-if="breadcrumb.text == 'Home'">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.0025 2.38854C10.4323 1.87627 9.56767 1.87626 8.9975 2.38854L3.4975 7.33008C3.18086 7.61457 3 8.0202 3 8.44587V15.5C3 16.3284 3.67157 17 4.5 17H6.5C7.32843 17 8 16.3284 8 15.5V11.5C8 11.2239 8.22386 11 8.5 11H11.5C11.7761 11 12 11.2239 12 11.5V15.5C12 16.3284 12.6716 17 13.5 17H15.5C16.3284 17 17 16.3284 17 15.5V8.44587C17 8.0202 16.8191 7.61457 16.5025 7.33008L11.0025 2.38854Z"
                fill="#6C707D" />
            </svg>
          </span>

          <span v-else-if="breadcrumb.text == 'clubname'">
            {{ clubName }}
          </span>
          <span v-else-if="breadcrumb.text == 'campaignName'">
            {{ emailCampaignName }}
          </span>
          <span v-else-if="breadcrumb.text == 'flowAutomationName'">
            {{ flowAutomationName }}
          </span>
          <span v-else-if="breadcrumb.name == 'templateName'">
            {{ templateName }}
          </span>
          <span v-else-if="breadcrumb.text == 'listName'">
            {{ listName }}
          </span>
          <span v-else-if="breadcrumb.text == 'segmentName'">
            {{ segmentName }}
          </span>
          <span v-else>
            {{ breadcrumb.text }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      breadcrumbList: [],
      cbName: "None",
    };
  },
  mounted() {
    // this.$store.dispatch("getClub", this.$route.params.id);
    this.updateList();
  },
  computed: {
    club() {
      return this.$store.state.club.club;
    },
    clubName() {
      return this.$store.state.club.club.name;
    },
    emailCampaignName() {
      return this.$store.state.commonEmailCampaign.emailCampaignName;
    },
    templateName() {
      return this.$route.query.title;
    },
    listName() {
      return this.$route.query.listName;
    },
    segmentName() {
      return this.$route.query.segmentName;
    },
    flowAutomationName() {
      return this.$route.query.flowAutomationName;
    },
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].to) {
        if (this.type) {
          this.$router.push({
            name: this.breadcrumbList[pRouteTo].to.name,
            query: { type: this.type },
          });
        } else {
          this.$router.push(this.breadcrumbList[pRouteTo].to);
        }
      }
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>
