<template src="./DeleteTagModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { removeTag } from "../../../../../../services/clubDetails/tag.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    ids: {
      type: Array,
      default: [],
    },
  },
  computed: {},
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      isLoader: false,
    };
  },
  methods: {
    deleteList() {
      this.isLoader = true;
      var mod = {
        ids: this.ids,
        clubId: this.$route.params.id,
      };

      removeTag(mod)
        .then((response) => {
          this.isLoader = false;
          let payload = {
            type: "success",
            Message: "Selected tag has been deleted.",
          };
          this.$store.dispatch("showNotification", payload);
          this.$emit("refetchTags");
          this.$emit("closeDeleteModal");
        })
        .catch((error) => {
          this.isLoader = false;
        });
    },
    modalHandler(data) {
      this.$emit("closeDeleteModal");
    },
  },
};
</script>
