<modal
  :title="'Edit Tag'"
  :handler="true"
  :isLoading="isLoading"
  type="pulsing"
  :cssClass="'c-modal-small-2'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <div class="k-form-field" v-if="!isLoading">
      <label>Tag Name *</label>
      <k-input
        :class="'mb-0'"
        type="text"
        v-model="tagDetails.name"
        placeholder="e.g. Influencer"
      >
      </k-input>
      <span class="k-form-error d-block ms-1 mt-2 position-relative"
        >{{this.errors?.Name?.[0]}}</span
      >
    </div>
  </template>
  <template v-slot:footer :class="'justify-content-end modal-footer'">
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton
          @click="modalHandler"
          :class="'flex-none border-normal-1 base-color'"
          :fill-mode="'outline'"
          :theme-color="'primary'"
          >Cancel</kbutton
        >
      </div>
      <div class="me-1">
        <kbutton :theme-color="'primary'" :class="'flex-none'" @click="update"
          >Update</kbutton
        >
      </div>
    </div>
  </template>
</modal>
