<AppSnackBar
  :message="showMsg.Message"
  :notificationType="showMsg.type"
  v-if="isShowMsg"
></AppSnackBar>
<app-loader v-if="isGetTagLoading || isLoadderLocal "></app-loader>

<div class="dashboard-right-inner">
  <Breadcrumb :id="$route.params.id" />
  <div class="min-height-calc-100-182 max-height-100--60 overflow-y-auto">
    <div class="dashboard-header p-0 box-shadow-none bg-transparent">
      <div class="px-20 px-10-sm">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100-vsm pt-12 pb-16 w-100 border-bottom-normal-1 mb-16"
        >
          <h2 class="font-18 font-w-600 mb-2 mb-sm-0">Tags</h2>
          <div>
            <kbutton
              v-if="tagsData?.items?.length>0 || isGetTagLoading || searchWord || IsSearch"
              :disabled="!hasCreateAccess()"
              @click="createTag"
              :class="'w-100-vsm h-38 font-w-500'"
              :theme-color="'primary'"
            >
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 7.5V3.5H7V7.5H3V9.5H7V13.5H9V9.5H13V7.5H9Z"
                  fill="white"
                />
              </svg>
              Create Tag</kbutton
            >
          </div>
        </div>
      </div>
    </div>

    <h2 class="font-28 font-34-sm-big font-w-300">{{part}}</h2>

    <div class="dashboard-content py-0 px-20 px-10-sm">
      <div
        v-if="tagsData?.items?.length==0 && !isGetTagLoading && !searchWord && !IsSearch"
        class="dashboard-content bg-white radius-5 min-height-calc-100-182 d-flex justify-content-center align-items-center"
      >
        <div class="width-747 text-center">
          <svg
            width="217"
            height="184"
            viewBox="0 0 217 184"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.05" cx="108" cy="92" r="92" fill="#253858" />
            <g filter="url(#filter0_bdd_666_102160)">
              <rect
                x="31.7822"
                y="53.0176"
                width="154.563"
                height="98.2819"
                rx="1.93432"
                fill="white"
                fill-opacity="0.15"
                shape-rendering="crispEdges"
              />
            </g>
            <g filter="url(#filter1_bd_666_102160)">
              <rect
                x="28.0996"
                y="43.9238"
                width="161.928"
                height="102.965"
                rx="1.88497"
                fill="white"
                fill-opacity="0.15"
                shape-rendering="crispEdges"
              />
            </g>
            <g filter="url(#filter2_dd_666_102160)">
              <rect
                x="23"
                y="33"
                width="170.842"
                height="108.97"
                rx="3.69388"
                fill="white"
              />
            </g>
            <line
              x1="36"
              y1="86.5383"
              x2="180"
              y2="86.5383"
              stroke="black"
              stroke-opacity="0.04"
              stroke-width="0.923471"
            />
            <rect
              opacity="0.2"
              x="38"
              y="47"
              width="27.7041"
              height="27.7041"
              rx="13.8521"
              fill="#14C57B"
            />
            <rect
              opacity="0.6"
              x="76"
              y="49"
              width="55"
              height="6"
              rx="3"
              fill="#C7CBDB"
            />
            <rect
              opacity="0.3"
              x="76"
              y="62"
              width="104"
              height="3.5"
              rx="1.75"
              fill="#C7CBDB"
            />
            <rect
              opacity="0.3"
              x="76"
              y="71"
              width="94"
              height="3.5"
              rx="1.75"
              fill="#C7CBDB"
            />
            <mask
              id="mask0_666_102160"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="38"
              y="45"
              width="28"
              height="28"
            >
              <rect
                x="38"
                y="45"
                width="27.7041"
                height="27.7041"
                rx="13.8521"
                fill="#A4C3F3"
              />
            </mask>
            <g mask="url(#mask0_666_102160)">
              <path
                d="M48.6959 62.9591C49.846 62.4912 50.9474 62.2573 52 62.2573C53.0526 62.2573 54.1442 62.4912 55.2749 62.9591C56.425 63.4074 57 64.0019 57 64.7427C57 65.4371 56.4371 66 55.7427 66H48.2573C47.5629 66 47 65.4371 47 64.7427C47 64.0019 47.5653 63.4074 48.6959 62.9591ZM53.7544 60.269C53.2671 60.7563 52.6823 61 52 61C51.3177 61 50.7329 60.7563 50.2456 60.269C49.7583 59.7817 49.5146 59.1969 49.5146 58.5146C49.5146 57.8324 49.7583 57.2476 50.2456 56.7602C50.7329 56.2534 51.3177 56 52 56C52.6823 56 53.2671 56.2534 53.7544 56.7602C54.2417 57.2476 54.4854 57.8324 54.4854 58.5146C54.4854 59.1969 54.2417 59.7817 53.7544 60.269Z"
                fill="#14C57B"
              />
            </g>
            <rect
              opacity="0.2"
              x="38"
              y="98"
              width="27.7041"
              height="27.7041"
              rx="13.8521"
              fill="#9B5FFC"
            />
            <rect
              opacity="0.6"
              x="76"
              y="100"
              width="55"
              height="6"
              rx="3"
              fill="#C7CBDB"
            />
            <rect
              opacity="0.3"
              x="76"
              y="113"
              width="104"
              height="3.5"
              rx="1.75"
              fill="#C7CBDB"
            />
            <rect
              opacity="0.3"
              x="76"
              y="122"
              width="94"
              height="3.5"
              rx="1.75"
              fill="#C7CBDB"
            />
            <mask
              id="mask1_666_102160"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="38"
              y="96"
              width="28"
              height="28"
            >
              <rect
                x="38"
                y="96"
                width="27.7041"
                height="27.7041"
                rx="13.8521"
                fill="#A4C3F3"
              />
            </mask>
            <g mask="url(#mask1_666_102160)">
              <path
                d="M48.6959 112.959C49.846 112.491 50.9474 112.257 52 112.257C53.0526 112.257 54.1442 112.491 55.2749 112.959C56.425 113.407 57 114.002 57 114.743C57 115.437 56.4371 116 55.7427 116H48.2573C47.5629 116 47 115.437 47 114.743C47 114.002 47.5653 113.407 48.6959 112.959ZM53.7544 110.269C53.2671 110.756 52.6823 111 52 111C51.3177 111 50.7329 110.756 50.2456 110.269C49.7583 109.782 49.5146 109.197 49.5146 108.515C49.5146 107.832 49.7583 107.248 50.2456 106.76C50.7329 106.253 51.3177 106 52 106C52.6823 106 53.2671 106.253 53.7544 106.76C54.2417 107.248 54.4854 107.832 54.4854 108.515C54.4854 109.197 54.2417 109.782 53.7544 110.269Z"
                fill="#9B5FFC"
              />
            </g>
            <defs>
              <filter
                id="filter0_bdd_666_102160"
                x="23.7431"
                y="44.9784"
                width="170.641"
                height="114.36"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="4.01957" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_666_102160"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.81524" />
                <feGaussianBlur stdDeviation="1.81524" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_backgroundBlur_666_102160"
                  result="effect2_dropShadow_666_102160"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4.01957" />
                <feGaussianBlur stdDeviation="2.00978" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect2_dropShadow_666_102160"
                  result="effect3_dropShadow_666_102160"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect3_dropShadow_666_102160"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_bd_666_102160"
                x="20.3047"
                y="36.1289"
                width="177.518"
                height="118.555"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="3.89744" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_666_102160"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.76009" />
                <feGaussianBlur stdDeviation="1.76009" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_backgroundBlur_666_102160"
                  result="effect2_dropShadow_666_102160"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_666_102160"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_dd_666_102160"
                x="0.8367"
                y="18.2245"
                width="215.169"
                height="153.296"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="7.38777" />
                <feGaussianBlur stdDeviation="11.0816" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.04 0 0 0 0 0.168627 0 0 0 0 0.36 0 0 0 0.08 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_666_102160"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.84694" />
                <feGaussianBlur stdDeviation="1.84694" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feBlend
                  mode="normal"
                  in2="effect1_dropShadow_666_102160"
                  result="effect2_dropShadow_666_102160"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect2_dropShadow_666_102160"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>

          <p class="color-light2 mt-3 mb-5">
            Create a tag to distinguish between your audience.
          </p>
          <kbutton
            v-if="!tagsData.items?.length"
            :disabled="!hasCreateAccess()"
            :theme-color="'primary'"
            @click="createTag"
          >
            + Create Tag
          </kbutton>
        </div>
      </div>

      <div
        v-if="tagsData?.items?.length>0 || isGetTagLoading || searchWord || IsSearch"
      >
        <Grid
          ref="grid"
          :class="'first-col-checkbox-center mb-60 table-design-2 th-td-checkbox-style-2 tags-table-wrap'"
          :data-items="tagsData.items"
          :selectable="true"
          :selected-field="selectedField"
          :columns="columns"
          @selectionchange="onSelectionChange"
          @datastatechange="dataStateChange"
          @headerselectionchange="onHeaderSelectionChange"
          :skip="skip"
          :take="take"
          :total="total"
          :pageable="gridPageable"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
        >
          <template v-slot:tagname="{props}">
            <td>
              <span
                @click="viewTag(props.dataItem)"
                class="font-14 font-w-500 color-blue cursor-pointer"
                >{{isNameLimit(props.dataItem.name)}}</span
              >
            </td>
          </template>

          <template v-slot:audienceCount="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span>{{props.dataItem.audienceCount}}</span>
              </div>
            </td>
          </template>

          <template v-slot:createdAt="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span>{{getDate(props.dataItem.createdAt)}}</span>
              </div>
            </td>
          </template>
          <template v-slot:modifiedOn="{props}">
            <td>
              <div class="d-flex justify-content-between">
                <span v-if="props.dataItem.updatedAt"
                  >{{getDate(props.dataItem.updatedAt)}}</span
                >
                <span v-else>-</span>
              </div>
            </td>
          </template>

          <template v-slot:actions="{props}">
            <td>
              <AppPopUp
                v-if="actionsDropDown?.length"
                :voucher="props.dataItem"
                :items="actionsDropDown"
                @dropId="onItemClick($event,props.dataItem)"
                :icon="'more-vertical'"
                :popUpSettings="popupSettings"
                :buttonClass="'btn-clear'"
                @onOpen="onOpen"
              >
              </AppPopUp>
            </td>
          </template>

          <toolbar>
            <div class="d-flex flex-wrap justify-content-between w-100">
              <div
                class="search-box-wrap width-220 w-100-vsm me-0 me-sm-3 mb-3 mb-sm-0"
              >
                <span class="k-icon k-i-zoom"></span>
                <span class="k-textbox k-grid-search k-display-flex">
                  <k-input
                    :class="'mb-0 width-220 w-100-vsm'"
                    placeholder="Search"
                    :value="searchWord"
                    @input="onSearch"
                  >
                  </k-input>
                </span>
              </div>
              <div class="w-100-vsm">
                <kbutton
                  :disabled="!hasDeleteAccess()"
                  v-if="selectedItems.length>0"
                  :class="'w-100-vsm border-normal-1 text-color-red-2 bg-white hvr-bg-red-2 hvr-border-red-2 mt-1px'"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  :icon="'delete'"
                  @click="deleteTag"
                >
                  Delete
                </kbutton>
              </div>
            </div>
          </toolbar>

          <GridNoRecords> No tags found. </GridNoRecords>
        </Grid>
      </div>
    </div>
    <DeleteTagModal
      v-if="deleteModalVisible"
      @closeDeleteModal="closeDeleteModal"
      @cancelBtnDeleteModal="cancelBtnDeleteModal"
      :ids="rowId"
      @refetchTags="refetchTags"
    >
    </DeleteTagModal>
    <CreateTagModal
      @closeModal="closeModal"
      v-if="modalVisible"
      @refetchTags="refetchTags"
    ></CreateTagModal>
    <EditTagModal
      @closeEditModal="closeEditModal"
      v-if="editModalVisible"
      :rowId="fieldrowId"
      @refetchTags="refetchTags"
    ></EditTagModal>
  </div>
</div>
