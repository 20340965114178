<template src="./Tags.html"></template>
<style lang="scss">
@import "./Tags.scss";
</style>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { Button, ButtonGroup } from "@progress/kendo-vue-buttons";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import Breadcrumb from "../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import CreateTagModal from "./modals/createTagModal/CreateTagModal.vue";
import DeleteTagModal from "./modals/deleteTagModal/DeleteTagModal.vue";
import AppLoader from "../../../common/AppLoader/AppLoader";
import AppSnackBar from "../../../common/AppSnackBar/AppSnackBar";
import moment from "moment";
import EditTagModal from "./modals/editTagModal/EditTagModal.vue";
import axios from "axios";
import SnackBarModel from "@/models/snackBar/snackBarModel";
import { configuration } from "@/configurationProvider";
import { auth } from "@/services/auth.service";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";
import GetAudienceModel from "@/models/audience/allAudience/GetAudienceModel";

export default {
  name: "Tags",
  components: {
    AppPopUp,
    Breadcrumb,
    buttongroup: ButtonGroup,
    Grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    kbutton: Button,
    DropDownButton,
    CreateTagModal,
    DeleteTagModal,
    "app-loader": AppLoader,
    AppSnackBar,
    EditTagModal,
    GridNoRecords,
  },
  data() {
    return {
      itemTagTypes: {
        1: "dpAudience",
        2: "ecAudience",
        3: "vAudience",
      },
      moduleTagTypes: {
        1: "digitalPass",
        2: "emailCampaign",
        3: "voucher",
      },
      childItemCodeType: {
        1: "dpTags",
        2: "ecTags",
        3: "vTags",
      },
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      clubId: this.$route.params.id,
      searchWord: "",
      selectedField: "selected",
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: "numeric",
        pageSizes: [10, 50, 100, 200, 500, 1000],
        previousNext: true,
      },
      selectedItems: [],
      documentIdPass: "",
      selectedMembers: [],
      selectedMemberList: [],
      actions: "actions",
      passStatuses: [],
      passStatusValue: { id: 0, text: "All" },
      modalVisible: false,
      deleteModalVisible: false,
      editModalVisible: false,
      fieldrowId: "",
      timer: null,
      IsSearch: false,
      isLoadderLocal: false,
      actionsDropDown: [],
      sort: [],
      sortBy: "",
      sortDir: "",
      rowId: [],
    };
  },
  computed: {
    areAllSelected() {
      return (
        this.selectedItems?.length === this.tagsData.items?.length &&
        this.tagsData.items?.length != 0
      );
    },
    columns() {
      return [
        {
          field: "selected",
          headerSelectionValue: this.areAllSelected,
          width: "42px",
        },
        {
          field: "tagName",
          title: "Name",
          cell: "tagname",
          sortable: false,
        },
        {
          field: "audienceCount",
          title: "Total Audience",
          cell: "audienceCount",
          sortable: true,
        },
        {
          field: "createdAt",
          title: "Created On",
          cell: "createdAt",
          sortable: true,
        },
        {
          field: "modifiedOn",
          title: "Modified On",
          cell: "modifiedOn",
          sortable: false,
        },
        {
          field: "actions",
          title: "Actions",
          cell: "actions",
          sortable: false,
        },
      ];
    },
    tagsData() {
      return this.$store.state.tag.tags;
    },
    isShowMsg() {
      return this.$store.state.common.showMsg;
    },
    showMsg() {
      return this.$store.state.common.status;
    },
    isGetTagLoading() {
      return this.$store.state.tag.isLoading;
    },
    total() {
      return this.tagsData ? this.tagsData.size : 0;
    },
    take() {
      return this.tagsData ? this.tagsData.limit || 100 : 100;
    },
    skip() {
      return this.tagsData ? this.tagsData.offset : 0;
    },
    fetchController() {
      return this.$store.state.tag.fetchController;
    },
  },
  watch: {
    "$route.query.type"() {
      this.selectedItems = [];
      this.searchWord = "";
      if (!this.hasGetAccess()) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You do not have access to view campaigns.",
          type: "warning",
        });
        this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
      }
      this.passStatuses = [
        ...(this.hasEditAccess() ? [{ id: 0, text: "Rename" }] : []),
        ...(!this.hasCreateAccess() ? [{ id: 1, text: "Export" }] : []),
        ...(this.hasDeleteAccess() ? [{ id: 2, text: "Delete" }] : []),
      ];
      this.actionsDropDown = [
        ...(this.hasEditAccess() ? [{ dropId: 1, text: "Rename" }] : []),
        ...(this.hasCreateAccess() ? [{ dropId: 2, text: "Export" }] : []),
        ...(this.hasDeleteAccess() ? [{ dropId: 3, text: "Delete" }] : []),
      ];
    },
  },
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    this.passStatuses = [
      ...(this.hasEditAccess() ? [{ id: 0, text: "Rename" }] : []),
      ...(this.hasCreateAccess() ? [{ id: 1, text: "Export" }] : []),
      ...(this.hasDeleteAccess() ? [{ id: 2, text: "Delete" }] : []),
    ];
    this.actionsDropDown = [
      ...(this.hasEditAccess() ? [{ id: 0, dropId: 1, text: "Rename" }] : []),
      ...(this.hasCreateAccess() ? [{ id: 1, dropId: 2, text: "Export" }] : []),
      ...(this.hasDeleteAccess() ? [{ id: 1, dropId: 3, text: "Delete" }] : []),
    ];

    this.part;
    this.getTags();
  },
  unmounted() {
    this.$store.commit("SET_ALL_TAGS", []);
    this.selectedItems = [];
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleTagTypes[+this.$route.query.type],
        itemCode: this.itemTagTypes[+this.$route.query.type],
        childItemCode: this.childItemCodeType[+this.$route.query.type],
      });
    },
    getTags() {
      var model = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        searchQuery: " ",
        limit: 100,
      };
      this.$store.dispatch("getTags", model);
    },
    onHeaderSelectionChange(event) {
      var allChecked = event.event.target.checked;
      this.tagsData.items = this.tagsData.items.map((item) => {
        return { ...item, selected: allChecked };
      });
      if (allChecked) {
        this.selectedItems = [];
        let members = this.tagsData.items;
        for (const key in members) {
          if (Object.hasOwnProperty.call(members, key)) {
            this.selectedItems.push(members[key].id);
            this.selectedMemberList.push(members[key]);
          }
        }
      } else {
        this.selectedItems = [];
      }
    },
    payloadGenerator() {
      let payload = new GetAudienceModel();
      payload = {
        ...payload,
        clubId: this.clubId,
        searchQuery: this.searchWord,
        entityType: +this.$route.query.type,
      };
      if (this.sortBy && this.sortDir) {
        payload.orderBy = `${this.sortBy} ${this.sortDir}`;
      }
      return payload;
    },
    refetchTags(resetPagination = false) {
      let payload = this.payloadGenerator();

      if (resetPagination) {
        payload = {
          ...payload,
          limit: this.take,
          offset: 0,
        };
      } else {
        payload = {
          ...payload,
          limit: this.take,
          offset: this.skip,
        };
      }
      this.$store.dispatch("getTags", payload);
    },
    sortChangeHandler: function (e) {
      this.IsSearch = true;

      this.sort = e.sort;
      if (e.sort.length > 0) {
        this.sortBy = e.sort[0].field;
        this.sortDir = e.sort[0].dir;
      } else {
        this.sortBy = "";
        this.sortDir = "";
      }
      this.refetchTags(true);
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
      this.selectedItems = event.dataItems
        .filter((x) => x.selected === true)
        .map((x) => x.id);

      this.selectedMemberList = event.dataItems.filter(
        (x) => x.selected === true
      );
    },
    onSearch(e) {
      if (e.value == "") {
        this.IsSearch = true;
      }
      this.selectedItems = [];
      this.searchWord = e.value;
      this.$store.commit("SET_LOADER", true);
      // var model = {
      //   clubId: this.$route.params.id,
      //   entityType: +this.$route.query.type,
      //   searchQuery: this.searchWord,
      //   limit: this.take,
      // };
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        // this.$store.dispatch("getTags", model);
        this.refetchTags(true);
      }, 800);
    },
    dataStateChange: function (event) {
      this.take = event.data.take;
      this.skip = event.data.skip;
      let payload = {
        clubId: this.$route.params.id,
        searchQuery: this.searchWord,
        limit: event.data.take,
        offset: event.data.skip,
        entityType: this.$route.query.type || 1,
      };
      this.$store.dispatch("getTags", payload);
    },
    createTag() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
    closeDeleteModal() {
      this.deleteModalVisible = false;
      this.selectedItems = [];
      this.refetchTags(true);
    },
    cancelBtnDeleteModal() {
      this.deleteModalVisible = false;
    },
    deleteTag() {
      this.rowId = this.selectedItems;
      this.deleteModalVisible = true;
    },
    deleteOneTag(e) {
      this.deleteModalVisible = true;
      this.selectedItems.push(e.item.id);
    },
    closeEditModal() {
      // var model = {
      //   clubId: this.$route.params.id,
      //   entityType: +this.$route.query.type,
      //   searchQuery: this.searchWord,
      // };
      // this.$store.dispatch("getTags", model);
      this.editModalVisible = false;
      this.refetchTags(true);
    },
    // onItemClick(e) {
    //   if (e.item.text == "Delete") {
    //     this.deleteModalVisible = true;
    //     this.selectedItems.push(this.fieldrowId);
    //   }
    //   if (e.item.text == "Rename") {
    //     this.editModalVisible = true;
    //   }
    //   if (e.item.text == "Export") {
    //     this.exportTag();
    //   }
    // },
    onItemClick(dropId, data) {
      if (dropId == 1) {
        this.editModalVisible = true;
      }
      if (dropId == 2) {
        this.exportTag();
      }
      if (dropId == 3) {
        this.deleteModalVisible = true;
        this.rowId = [data.id];
      }
    },
    onOpen(e) {
      this.fieldrowId = e.id;
    },
    getDate(date) {
      var result = moment(date).format("DD-MMM-YYYY");
      return result;
    },
    viewTag(e) {
      if (this.$route.query.type == 3) {
        this.$router.push({
          name: "voucherAllAudience",
          query: {
            type: +this.$route.query.type,
            tagId: e.id,
            tagText: e.name,
          },
        });
      } else if (this.$route.query.type == 2) {
        this.$router.push({
          name: "EcAllAudience",
          query: {
            type: +this.$route.query.type,
            tagId: e.id,
            tagText: e.name,
          },
        });
      } else if (this.$route.query.type == 1) {
        this.$router.push({
          name: "DpAllAudience",
          query: {
            type: +this.$route.query.type,
            tagId: e.id,
            tagText: e.name,
          },
        });
      }
    },
    isNameLimit(name) {
      return name.length <= 50 ? name : name.substring(0, 50) + "...";
    },
    async exportTag() {
      this.isLoadderLocal = true;
      var payload = {
        entityType: this.$route.query.type,
        clubId: this.$route.params.id,
        tagId: this.fieldrowId,
      };

      let token = null;
      if (configuration.enableIdentityServer) {
        token = await auth.getAccessToken();
      } else {
        token = localStorage.getItem("clubeez_session");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios({
        url: `${configuration.apiBaseUrl}/api/v1/Audience/tags/${this.fieldrowId}/export`,
        method: "POST",
        responseType: "blob",
        headers: headers,
        data: payload,
      })
        .then((response) => {
          this.isLoadderLocal = false;
          let campaign =
            this.$route.query.type == 1
              ? "digital pass"
              : this.$route.query.type == 2
              ? "email camaign"
              : "voucher";
          // let tagName = this.$route.query.tagText || "hahahha";
          let tagName = this.tagsData.items.filter(
            (x) => x.id == this.fieldrowId
          )[0].name;

          var model = new SnackBarModel();
          model.type = "success";
          model.Message = "Audience exported successfully.";

          if (response) this.$store.dispatch("showNotification", model);

          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let filename = `Audience Tags of ${campaign} (${tagName}).xlsx`;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          this.isLoadderLocal = false;
          var model = new SnackBarModel();
          model.type = "error";
          model.Message = "No audience to export.";
          this.$store.dispatch("showNotification", model);
        });
    },
  },
};
</script>

<style></style>
