<template src="./CreateTagModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../../../../common/AppModal/AppModal";
import { addTag } from "@/services/clubDetails/tag.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  computed: {
    modalVisible() {
      return this.$store.state.club.deleteModalVisible; //need change
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      tagName: "",
      errors: [],
      isLoading: false,
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("DELETE_MODAL_VISIBLE", false); //need change
    },
    createNewTag() {
      // this.$store.commit("SET_LOADER", true);
      this.isLoading = true;
      var payload = {
        clubId: this.$route.params.id,
        entityType: +this.$route.query.type,
        name: this.tagName,
      };

      addTag(payload)
        .then((response) => {
          // this.$store.commit("SET_LOADER", false);
          this.isLoading = false;
          let payload = {
            type: "success",
            Message: "New Tag Created Successfully.",
          };
          this.$store.dispatch("showNotification", payload);
          this.$store.commit("SET_LOADER", false);
          this.$emit("refetchTags");
          this.$emit("closeModal");
        })
        .catch((error) => {
          this.isLoading = false;
          this.errors = error.response.data.errors;
        });
    },
    modalHandler(data) {
      this.$emit("closeModal");
    },
  },
};
</script>
